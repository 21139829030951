<!-- 编辑产业项目 -->
<template>
  <el-dialog
    title="编辑产业项目"
    :visible.sync="editShow"
    width="60%"
    @close="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <ax-form ref="formBox" :formBuilder="formBuilder" @change="onchange">
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="请选择所属网格"
          change-on-select
          :show-search="{ filter }"
          @change="onChange"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>

      <div slot="fileIds">
        <imageUpload ref="img" :businessId="businessId"></imageUpload>
      </div>
      <div slot="areaCode">
        <a-cascader
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :field-names="{
            label: 'label',
            value: 'label',
            children: 'children',
          }"
          :show-search="{ filter }"
          :options="options1"
          placeholder="请选择位置"
          @change="onChange1"
          allowClear
          v-decorator="[
            'options1',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </el-dialog>
</template>
<script>
import api from "./api";
import jsonData from "./data.json"; // 导入 JSON 文件
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
const options = [
  { label: "元", value: "1" },
  { label: "百元", value: "2" },
  { label: "千元", value: "3" },
  { label: "万元", value: "4" },
  { label: "亿元", value: "5" },
];
const formList = [
  {
    label: "项目名称",
    type: "",
    model: "projectName",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "项目名称不能为空",
      },
    ],
  },
  {
    label: "建设地点",
    type: "",
    model: "areaCode",
    options: { placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: true, message: "建设地点不能为空" }],
  },
  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 12 },
    rules: [{ required: true, message: "所属网格不能为空" }],
  },
  {
    label: "所属社区",
    type: "",
    model: "communityName",
    options: { placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
    rules: [{ required: false, message: "所属社区不能为空" }],
  },

  {
    label: "施工单位",
    type: "",
    model: "constructionUnit",
    options: { placeholder: "请输入", maxLength: 20 },
    col: { span: 24 },
  },
  {
    label: "合同金额",
    type: "",
    model: "contractAmount",
    options: {
      placeholder: "请输入",
      min: 0,
    },
    col: { span: 18 },
    rules: [
      {
        required: false,
        pattern: /^(0|[1-9]\d{0,8})(\.\d{1,4})?$|^(0|[1-9]\d{0,5}\.\d{1,4})$/,
        message: "合同金额不能小于0;小数点后四位",
      },
    ],
  },
  {
    label: "金额单位",
    type: "select",
    model: "contractPriceUnit",
    options: { placeholder: "请输入", options: options, defaultValue: "1" },
    col: { span: 6 },
  },
  {
    label: "审计金额",
    type: "",
    model: "auditAmount",
    options: { placeholder: "请输入", min: 0 },
    col: { span: 18 },
    rules: [
      {
        required: false,
        pattern: /^(0|[1-9]\d{0,8})(\.\d{1,4})?$|^(0|[1-9]\d{0,5}\.\d{1,4})$/,
        message: "审计金额不能小于0;小数点后四位",
      },
    ],
  },
  {
    label: "金额单位",
    type: "select",
    model: "auditPriceUnit",
    options: { placeholder: "请输入", options: options, defaultValue: "1" },
    col: { span: 6 },
  },
  {
    label: "签合同日期",
    type: "datePicker",
    model: "contractTime",
    options: { placeholder: "请输入" },
    col: { span: 12 },
  },
  {
    label: "建设工期",
    type: "rangePicker",
    model: "Time",
    col: { span: 12 },
    options: {},
  },

  {
    label: "正文",
    type: "textarea",
    model: "constructContent",
    options: { minRows: 8, maxRows: 8 },
    col: { span: 24 },
  },
  {
    label: "票据/发票",
    type: "",
    model: "fileIds",
    options: {},
    col: { span: 24 },
  },
];

export default {
  components: { imageUpload },
  data() {
    return {
      api,
      id: "",
      gridId: "",
      options: [],
      options1: jsonData, // 使用导入的 JSON 数据
      fileIds: [], //图片数组
      businessId: "",
      saveType: false,
      editShow: false,
      address: "",
      limitTimeStart: "",
      limitTimeEnd: "",
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
  },
  methods: {
    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 打开弹窗
    openModal(record = {}) {
      this.id = record.id;
      this.businessId = record.id;
      this.getData();
      this.$nextTick(() => {
        this.editShow = true;
      });
    },
    //查看
    async getData() {
      const res = await api.getById(this.id);
      this.$refs.formBox.resetFields();
      this.limitTimeStart = res.data.limitTimeStart || "";
      this.limitTimeEnd = res.data.limitTimeEnd || "";
      this.address = res.data.areaCode; // 初始化 areaCode
      this.gridId = res.data.gridId; // 假设这是你的目标 ID
      // 将两个日期用逗号连接成一个字符串
      const combinedDates = this.limitTimeStart + "," + this.limitTimeEnd;
      const address2 = res.data.areaCode.split("/"); // 将字符串转换为数组
      this.$refs.formBox.setFieldsValue({
        ...res.data,
        options1: address2,
        Time: combinedDates,
      });
      // 获取网格树数据
      const gridTreeRes = await api.getTreeList();
      if (gridTreeRes.success) {
        const gridTreeData = this.processData(gridTreeRes.data); // 处理数据

        //找到父级 //递归地遍历树结构数据，并构建从根节点到目标节点的路径
        const findNodePath = (data, targetValue, path = []) => {
          for (const node of data) {
            const currentPath = [...path, node.value];
            if (node.value === targetValue) {
              return currentPath;
            }
            if (node.children && node.children.length > 0) {
              const result = findNodePath(
                node.children,
                targetValue,
                currentPath
              );
              if (result) {
                return result;
              }
            }
          }
          return null;
        };
        // 模拟获取的gridId

        //所有id
        const nodePath = findNodePath(gridTreeData, this.gridId);

        this.$refs.formBox.setFieldsValue({
          options: nodePath,
        });
      } else {
        console.error("获取网格树数据失败:", gridTreeRes.message);
      }
    },
    onchange(e, type) {
      if (type === "Time") {
        // 使用 split() 方法将字符串分割成数组
        let datesArray = e.split(",");
        // 将数组的元素赋值给 this.banStartDate 和 this.banEndDate
        this.limitTimeStart = datesArray[0];
        this.limitTimeEnd = datesArray[1];
      }
    },

    // 级联选择器改变的回调(地理位置)
    onChange1(value, selectedOptions) {
      if (Array.isArray(value) && Array.isArray(selectedOptions)) {
        const labelString = selectedOptions
          .map((option) => option.label)
          .join("/");
        const valueString = value.join("/");
        this.address = valueString;

        return { value: valueString, label: labelString };
      } else {
        console.error(
          "Expected arrays for value and selectedOptions, but got:",
          value,
          selectedOptions
        );
        return { value: "", label: "" };
      }
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      this.gridId = lastId;
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.id = this.id;
        value.gridId = this.gridId;
        value.areaName = this.quillHtmls;
        value.areaCode = this.address;
        value.limitTimeStart = this.limitTimeStart;
        value.limitTimeEnd = this.limitTimeEnd;
        console.log(value);
        this.fileIds = [];
        const files = this.$refs.img.getFile();
        const files1 = this.$refs.img.getFileListAll();
        if (files1.length > 0) {
          const ids = files1.map((item) => {
            return item.uid;
          });
          const res = await api.del(ids.join(","));
        }
        files.map((item) => {
          if (item.response) {
            this.fileIds.push(item.response.data.id);
          } else {
            this.fileIds.push(item.uid);
          }
        });
        value.fileIds = [...new Set([...this.fileIds])];
        try {
          const res = await api.update(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.saveType = false;
            this.closeDialog();
            this.refreshTable();
          } else {
            this.$message.error("编辑失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("编辑失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.id = "";
      this.businessId = "";
      this.quillHtmls = "";
      this.editShow = false;
      this.$refs.img.removeFileList();
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-form input[type="file"] {
  display: none !important;
}
.el-upload__tip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 2%;
  margin-top: 4%;
  span {
    font-weight: 400;
    font-size: 12px;
    color: #bbbdbf;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
.button2 {
  display: flex;
  justify-content: space-between;
}
</style>
    