import { post, get,deletes, downFile } from 'common/request'

export default {
 //分页查询
list: args => get('/industryProject/list', args),
//查询所有
listAll: args => get('/industryProject/listAll', args),
//根据ID查询
getById: id => get(`/industryProject/getById?id=${id}`),
//新增
add: args => post('/industryProject/add', args),
//修改
update: args => post('/industryProject/update', args),
 //根据ID删除
deleteById: id => deletes(`/industryProject/deleteById?id=${id}`),
//根据IDS删除 
ids: args => deletes(`/industryProject/ids?ids=${args}`),  
// 网格树
gridTree:args => get('/scGridManagement/getTreeList', args),
//获取网格树
getTreeList: args => get('/scGridManagement/getTreeList', args),
  // 回显
getByBiz: args => get(`/file/getByBiz/?bizId=${args}`),

del: args => get(`/file/del/?id=${args}`),
}
